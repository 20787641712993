import { message } from "antd";
export const success = (title) => {
  message.success(title);
};
export function formatCurrency(
  number,
  decimalPlaces = 0,
  currencySymbol = "",
  thousandsSeparator = ","
) {
  const formattedNumber = number
    .toFixed(decimalPlaces)
    .replace(/\d(?=(\d{3})+w32\.)/g, `$&${thousandsSeparator}`);
  return currencySymbol + formattedNumber;
}

export function formatAmountInPKR(amount) {
  if (!amount) return 0;
  // Use Intl.NumberFormat with 'en-PK' locale to format the amount in PKR
  const formattedAmount = new Intl.NumberFormat("en-PK", {
    style: "currency",
    currency: "PKR"
  }).format(amount);

  return formattedAmount;
}

export const error = (title) => {
  message.error(title);
};
export const warning = (title) => {
  message.warning(title);
};

export const normFile = (info) => {
  if (info.file.status !== "uploading") {
    console.log(info.file, info.fileList);
  }
  if (info.file.status === "done") {
    success(`${info.file.name} file uploaded successfully`);
  } else if (info.file?.status === "error") {
    error(`${info.file.name} file upload failed.`);
  }

  return info?.fileList?.map((p) => ({
    url: p.response ? p.response?.link : p.url,
    thumbUrl: p.response ? p.response?.link : p.url,
    uid: p.uid,
    name: p.name.replaceAll(" ", "-"),
    status: p.status,
    type: p.type
  }));
};

export const onFinishFailed = (errorInfo) => {
  errorInfo.errorFields.map((p) => error(p.errors[0]));
};
