const folderList = [
  { id: 120, name: "All", alias: "all", icon: "user" },
  { id: 121, name: "Starred", alias: "starred", icon: "star" },
  { id: 122, name: "Priority", alias: "priority", icon: "envelope" },
  { id: 123, name: "Scheduled", alias: "scheduled", icon: "clock" },
  { id: 124, name: "Today", alias: "today", icon: "calendar-minus" },
  { id: 125, name: "Completed", alias: "completed", icon: "check-circle" },
  { id: 126, name: "Deleted", alias: "deleted", icon: "trash-alt" }
];
export default folderList;
