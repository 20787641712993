const title = "Donor";
const dataRoute = "erp/donor";
const navPath = "/donor";

export const donorInfo = {
  formTitle: title,
  LogTitle: "Donor",
  tableTitle: title,
  metaData: title,
  dataRoute,
  navPath,
  title,
  permission: "donor" //user //admin // moduleName
};
