const title = "Users";
const dataRoute = "admin";
const navPath = "/settings/user";

export const employeInfo = {
  formTitle: title,
  LogTitle: title,
  tableTitle: title,
  metaData: title,
  permission: "employee", //user //admin // moduleName
  dataRoute,
  navPath,
  title
};
