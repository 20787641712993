import React, { useState } from "react";
import { AutoComplete, Avatar, Card, Descriptions, Input, message } from "antd";
import { UserOutlined } from "@ant-design/icons";
// Import your axios instance
import {
  StyledHeaderAutoCompleteMinibar,
  StyledHeaderSearchMinibar
} from "./index.styled";
import jwtAxios from "@mj/services/auth/jwt-auth";
import { v4 as uuidv4 } from "uuid";
import { FaDonate } from "react-icons/fa";
import errorHandler from "@mj/services/auth/errorHandler";
import { useNavigate } from "react-router-dom";
import { usePermissions } from "@mj/custom/CheckPermission";
import { RoutePermittedRole } from "@mj/constants/AppEnums";
import { dataInfo } from "modules/DMS/Donation/Codes";
import { employeInfo } from "modules/root/Employee/Employee/Code";
import { donorInfo } from "modules/DMS/Donor/Codes";
import { useIntl } from "react-intl";

const SearchComponent = () => {
  const { canRead } = usePermissions(
    RoutePermittedRole[dataInfo.permission3 || "admin"]
  );
  const { canRead: canReadAdmin } = usePermissions(
    RoutePermittedRole[employeInfo.permission3 || "admin"]
  );
  const { canRead: canReadDonor } = usePermissions(
    RoutePermittedRole[donorInfo.permission3 || "admin"]
  );
  const [searchResults, setSearchResults] = useState([]);
  const router = useNavigate();
  const { messages } = useIntl();
  const handleSearch = async (value) => {
    if (value !== "") {
      try {
        const res = await jwtAxios.get(`meta/header/search?q=${value}`);
        setSearchResults(res.data?.result);
        console.log(res.data.result);
      } catch (error) {
        errorHandler(error);
      }
    }
  };
  const renderItem = (title, name, key, obj) => ({
    value: title,
    key: key,
    label: (
      <div
        style={{
          // display: 'flex',
          justifyContent: "space-between"
        }}
        onClick={() =>
          router(
            title == "User"
              ? `settings/user/workspace/${obj?._id}`
              : `/donor/workspace/${obj?._id}`
          )
        }
      >
        <Card size="small">
          <Card.Meta
            avatar={
              title == "User" ? (
                <Avatar
                  src={
                    obj?.picture[0]?.url ||
                    obj.avatar ||
                    "https://api.dicebear.com/7.x/miniavs/svg?seed=8"
                  }
                />
              ) : (
                <Avatar
                  icon={title == "Donation" ? <FaDonate /> : <UserOutlined />}
                />
              )
            }
            title={name}
            description={obj.email}
          />
        </Card>
      </div>
    )
  });

  const renderItem2 = (title, name, key, obj) => ({
    value: title,
    key: key,
    label: (
      <div
        style={{
          // display: 'flex',
          justifyContent: "space-between"
        }}
        className="donation-section"
        onClick={() => router(`/donation/workspace/${obj?._id}`)}
      >
        <Card size="small">
          <Card.Meta
            avatar={
              title == "User" ? (
                <Avatar
                  src={
                    obj?.picture[0]?.url ||
                    obj.avatar ||
                    "https://api.dicebear.com/7.x/miniavs/svg?seed=8"
                  }
                />
              ) : (
                ""
                // <Avatar
                //   icon={title == 'Donation' ? <FaDonate /> : <UserOutlined />}
                // />
              )
            }
            // title={name}
            description={
              <Descriptions
                title={name}
                column={1}
                items={[
                  {
                    key: "1",
                    label: "Id",
                    children: obj.id || obj.cuId
                  },
                  {
                    key: "2",
                    label: "Donor",
                    children: `${obj?.donor[0]?.name} ${obj?.donor[0]?.surName}`
                  },
                  {
                    key: "3",
                    label: "Purpose Owner Name",
                    children: obj.purposeDonor
                  }
                ]}
              />
            }
          />
        </Card>
      </div>
    )
  });
  const options = searchResults
    ?.map((result, index) => {
      let filteredData = [];
      switch (result.module) {
        case "Admin":
          if (canReadAdmin)
            filteredData = {
              label: `User`,
              key: uuidv4(),
              options: result.data.map((item) => ({
                ...renderItem(
                  "User",
                  `${item.name} ${item.lastName}`,
                  uuidv4(),
                  item
                )
              }))
            };
          break;
        case "Donor":
          if (canReadDonor)
            filteredData = {
              label: `Donor ${result.data?.length}`,
              key: uuidv4(),
              options: result.data.map((item) => ({
                ...renderItem(
                  "Donor",
                  `${item.name} ${item.surName}`,
                  uuidv4(),
                  item
                )
              }))
            };
          break;
        case "Donation":
          if (canRead)
            filteredData = {
              label: `Donation ${result.data?.length}`,
              key: uuidv4(),
              options: result.data.map((item) => ({
                ...renderItem2("Donation", item.donationType, uuidv4(), item)
              }))
            };
          break;
      }

      return filteredData;
    })
    .flat();

  console.log(searchResults);
  return (
    <StyledHeaderAutoCompleteMinibar
      popupClassName="certain-category-search-dropdown"
      // popupMatchSelectWidth={500}
      style={{
        maxWidth: 350,
        width: 350
      }}
      notFoundContent={"No search result"}
      options={options}
      size="large"
      onSearch={handleSearch}
      placeholder={messages["mj.header-Search"]}
    >
      {/* <StyledHeaderSearchMinibar size='large' placeholder='input here' /> */}
    </StyledHeaderAutoCompleteMinibar>
  );
};

export default SearchComponent;
