import { blue, green, grey, red } from "@ant-design/colors";
import { getCurrentMonthDate } from "@mj/helpers/DateHelper";

const todoList = [
  {
    id: 456753,
    title: "Check the documents of audit",
    priority: { id: 4545554, name: "High", type: 1, color: red[5] },
    isStarred: true,
    isReplied: false,
    label: [
      {
        id: 213,
        name: "JQuery",
        alias: "jquery",
        label: "JQuery",
        value: "JQuery",
        color: green[5]
      }
    ],
    hasAttachments: true,
    sentAt: "10.30am",
    startDate: getCurrentMonthDate(29),
    endDate: getCurrentMonthDate(31),
    scheduleMobile: "5:30 PM",
    folderValue: 121,
    image: "/assets/images/dummy2.jpg",
    assignedTo: {
      id: 503,
      name: "Monty Panesar",
      image: "/assets/images/avatar/A18.jpg"
    },
    createdBy: {
      name: "Billy Bowden",
      image: "/assets/images/avatar/A22.jpg"
    },
    createdOn: getCurrentMonthDate(26),
    status: 1003,
    comments: [
      {
        comment:
          "Call John at 8:00 PM to cross check the things and get a feedback too.",
        name: "Rohit Sharma",
        image: "/assets/images/avatar/A22.jpg",
        date: getCurrentMonthDate(30),
        time: "2:05am"
      }
    ],
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
  {
    id: 435544534,
    title: "Arrange a trip for best performing staff members",
    priority: { id: 9384234, name: "Medium", type: 2, color: blue[5] },
    isStarred: true,
    isReplied: false,
    label: [
      {
        id: 213,
        name: "JQuery",
        alias: "jquery",
        label: "JQuery",
        value: "JQuery",
        color: green[5]
      }
    ],
    hasAttachments: false,
    sentAt: "10.30am",
    startDate: getCurrentMonthDate(3),
    endDate: getCurrentMonthDate(6),
    scheduleMobile: "8:30 PM",
    folderValue: 124,
    image: "/assets/images/dummy2.jpg",
    assignedTo: {
      id: 505,
      name: "Andy Caddick",
      image: "/assets/images/avatar/A24.jpg"
    },
    createdBy: {
      name: "Mark Johnson",
      image: "/assets/images/avatar/A26.jpg"
    },
    createdOn: getCurrentMonthDate(18),
    status: 1002,
    comments: [
      {
        comment:
          "Call John at 8:00 PM to cross check the things and get a feedback too.",
        name: "Narendra Kumar",
        image: "/assets/images/avatar/A11.jpg",
        date: "Dec 21, 2021",
        time: "4:18am"
      }
    ],
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
  {
    id: 783232,
    title: "Call Adam to check the documentation",
    isStarred: true,
    label: [
      {
        id: 211,
        name: "Html",
        alias: "html",
        label: "Html",
        value: "Html",
        color: red[5]
      },
      {
        id: 212,
        name: "CSS",
        alias: "css",
        label: "CSS",
        value: "CSS",
        color: blue[5]
      }
    ],
    priority: { id: 4545554, name: "High", type: 1, color: red[5] },
    hasAttachments: false,
    sentAt: "10.30am",
    folderValue: 121,
    startDate: getCurrentMonthDate(18),
    endDate: getCurrentMonthDate(22),
    scheduleMobile: "8:30 PM",
    image: "/assets/images/dummy2.jpg",
    assignedTo: {
      id: 501,
      name: "Johnson",
      image: "/assets/images/avatar/A11.jpg"
    },
    createdBy: {
      name: "John David",
      image: "/assets/images/avatar/A25.jpg"
    },
    createdOn: getCurrentMonthDate(12),
    status: 1001,
    comments: [
      {
        comment:
          "The Task is very important, try to complete it so that we have time for testing.",
        name: "K L Sharma",
        image: "/assets/images/avatar/A21.jpg",
        date: getCurrentMonthDate(20)
      }
    ],
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
  {
    id: 32432435,
    title: "Call Adam to check the documentation",
    isStarred: true,
    label: [
      {
        id: 211,
        name: "Html",
        alias: "html",
        label: "Html",
        value: "Html",
        color: red[5]
      },
      {
        id: 212,
        name: "CSS",
        alias: "css",
        label: "CSS",
        value: "CSS",
        color: blue[5]
      }
    ],
    priority: { id: 4545554, name: "High", type: 1, color: red[5] },
    hasAttachments: false,
    sentAt: "10.30am",
    folderValue: 121,
    startDate: getCurrentMonthDate(30),
    endDate: getCurrentMonthDate(1),
    scheduleMobile: "8:30 PM",
    image: "/assets/images/dummy2.jpg",
    assignedTo: {
      id: 501,
      name: "Johnson",
      image: "/assets/images/avatar/A11.jpg"
    },
    createdBy: {
      name: "John David",
      image: "/assets/images/avatar/A25.jpg"
    },
    createdOn: getCurrentMonthDate(26),
    status: 1001,
    comments: [
      {
        comment:
          "The Task is very important, try to complete it so that we have time for testing.",
        name: "K L Sharma",
        image: "/assets/images/avatar/A21.jpg",
        date: getCurrentMonthDate(28),
        time: "2:05am"
      }
    ],
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
  {
    id: 454553,
    title: "Check the documents of audit",
    priority: { id: 9384234, name: "Medium", type: 2, color: blue[5] },
    isStarred: true,
    isReplied: false,
    label: [
      {
        id: 213,
        name: "JQuery",
        alias: "jquery",
        label: "JQuery",
        value: "JQuery",
        color: green[5]
      },
      {
        id: 211,
        name: "Html",
        alias: "html",
        label: "Html",
        value: "Html",
        color: red[5]
      }
    ],
    hasAttachments: false,
    sentAt: "10.30am",
    startDate: getCurrentMonthDate(19),
    endDate: getCurrentMonthDate(22),
    scheduleMobile: "7:30 PM",
    folderValue: 123,
    image: "/assets/images/dummy2.jpg",
    assignedTo: {
      id: 502,
      name: "Joe Root",
      image: "/assets/images/avatar/A15.jpg"
    },
    createdBy: {
      name: "John Guetta",
      image: "/assets/images/avatar/A26.jpg"
    },
    createdOn: getCurrentMonthDate(10),
    status: 1002,
    comments: [
      {
        comment:
          "Call John at 8:00 PM to cross check the things and get a feedback too.",
        name: "M S Dhoni",
        image: "/assets/images/avatar/A1.jpg",
        date: getCurrentMonthDate(25),
        time: "2:05am"
      }
    ],
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
  {
    id: 5467454354,
    title: "Check the documents of audit",
    priority: { id: 9384234, name: "Medium", type: 2, color: blue[5] },
    isStarred: true,
    isReplied: false,
    label: [
      {
        id: 213,
        name: "JQuery",
        alias: "jquery",
        label: "JQuery",
        value: "JQuery",
        color: green[5]
      },
      {
        id: 211,
        name: "Html",
        alias: "html",
        label: "Html",
        value: "Html",
        color: red[5]
      }
    ],
    hasAttachments: false,
    sentAt: "10.30am",
    startDate: getCurrentMonthDate(9),
    endDate: getCurrentMonthDate(12),
    scheduleMobile: "7:30 PM",
    folderValue: 123,
    image: "/assets/images/dummy2.jpg",
    assignedTo: {
      id: 502,
      name: "Joe Root",
      image: "/assets/images/avatar/A15.jpg"
    },
    createdBy: {
      name: "John Guetta",
      image: "/assets/images/avatar/A26.jpg"
    },
    createdOn: getCurrentMonthDate(26),
    status: 1002,
    comments: [
      {
        comment:
          "Call John at 8:00 PM to cross check the things and get a feedback too.",
        name: "M S Dhoni",
        image: "/assets/images/avatar/A1.jpg",
        date: getCurrentMonthDate(28),
        time: "2:05am"
      }
    ],
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
  {
    id: 345655,
    title: "Arrange birthday party for the staff",
    priority: { id: 4354454, name: "Low", type: 3, color: green[5] },
    isStarred: false,
    isReplied: false,
    label: [
      {
        id: 214,
        name: "Node.js",
        alias: "node",
        label: "Node.js",
        value: "Node.js",
        color: grey[5]
      }
    ],
    hasAttachments: true,
    sentAt: "10.30am",
    startDate: getCurrentMonthDate(2),
    endDate: getCurrentMonthDate(4),
    scheduleMobile: "6:30 PM",
    isRead: true,
    folderValue: 124,
    image: "/assets/images/dummy2.jpg",
    assignedTo: {
      id: 503,
      name: "Monty Panesar",
      image: "/assets/images/avatar/A18.jpg"
    },
    createdBy: {
      name: "Nathan David",
      image: "/assets/images/avatar/A27.jpg"
    },
    createdOn: getCurrentMonthDate(19),
    status: 1003,
    comments: [
      {
        comment:
          "Call John at 8:00 PM to cross check the things and get a feedback too.",
        name: "Nikit Sharma",
        image: "/assets/images/avatar/A2.jpg",
        date: getCurrentMonthDate(21),
        time: "2:15am"
      }
    ],
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
  {
    id: 5432356454,
    title: "Arrange birthday party for the staff",
    priority: { id: 4354454, name: "Low", type: 3, color: green[5] },
    isStarred: false,
    isReplied: false,
    label: [
      {
        id: 214,
        name: "Node.js",
        alias: "node",
        label: "Node.js",
        value: "Node.js",
        color: grey[5]
      }
    ],
    hasAttachments: true,
    sentAt: "10.30am",
    startDate: getCurrentMonthDate(6),
    endDate: getCurrentMonthDate(12),
    scheduleMobile: "4:30 PM",
    isRead: true,
    folderValue: 124,
    image: "/assets/images/dummy2.jpg",
    assignedTo: {
      id: 503,
      name: "Monty Panesar",
      image: "/assets/images/avatar/A18.jpg"
    },
    createdBy: {
      name: "Nathan David",
      image: "/assets/images/avatar/A27.jpg"
    },
    createdOn: getCurrentMonthDate(25),
    status: 1003,
    comments: [
      {
        comment:
          "Call John at 8:00 PM to cross check the things and get a feedback too.",
        name: "Nikit Sharma",
        image: "/assets/images/avatar/A2.jpg",
        date: getCurrentMonthDate(27),
        time: "2:05am"
      }
    ],
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
  {
    id: 765645,
    title: "Go to marketing section for diwali gifts",
    priority: { id: 9384234, name: "Medium", type: 2, color: blue[5] },
    isStarred: true,
    isReplied: false,
    label: [
      {
        id: 211,
        name: "Html",
        alias: "html",
        label: "Html",
        value: "Html",
        color: red[5]
      },
      {
        id: 213,
        name: "JQuery",
        alias: "jquery",
        label: "JQuery",
        value: "JQuery",
        color: green[5]
      }
    ],
    hasAttachments: false,
    sentAt: "10.30am",
    startDate: getCurrentMonthDate(15),
    endDate: getCurrentMonthDate(18),
    scheduleMobile: "5:30 PM",
    folderValue: 211,
    image: "/assets/images/dummy2.jpg",
    assignedTo: {
      id: 504,
      name: "Darren Gough",
      image: "/assets/images/avatar/A21.jpg"
    },
    createdBy: {
      name: "Nathon Lyon",
      image: "/assets/images/avatar/A27.jpg"
    },
    createdOn: getCurrentMonthDate(12),
    status: 1001,
    comments: [
      {
        comment:
          "Call John at 8:00 PM to cross check the things and get a feedback too.",
        name: "Ravindra Jadeja",
        image: "/assets/images/avatar/A4.jpg",
        date: getCurrentMonthDate(22),
        time: "2:35am"
      }
    ],
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
  {
    id: 4324543234,
    title: "Go to marketing section for diwali gifts",
    priority: { id: 9384234, name: "Medium", type: 2, color: blue[5] },
    isStarred: true,
    isReplied: false,
    label: [
      {
        id: 211,
        name: "Html",
        alias: "html",
        label: "Html",
        value: "Html",
        color: red[5]
      },
      {
        id: 213,
        name: "JQuery",
        alias: "jquery",
        label: "JQuery",
        value: "JQuery",
        color: green[5]
      }
    ],
    hasAttachments: false,
    sentAt: "10.30am",
    startDate: getCurrentMonthDate(26),
    endDate: getCurrentMonthDate(29),
    scheduleMobile: "8:30 PM",
    folderValue: 211,
    image: "/assets/images/dummy2.jpg",
    assignedTo: {
      id: 504,
      name: "Darren Gough",
      image: "/assets/images/avatar/A21.jpg"
    },
    createdBy: {
      name: "Nathon Lyon",
      image: "/assets/images/avatar/A27.jpg"
    },
    createdOn: getCurrentMonthDate(22),
    status: 1001,
    comments: [
      {
        comment:
          "Call John at 8:00 PM to cross check the things and get a feedback too.",
        name: "Ravindra Jadeja",
        image: "/assets/images/avatar/A4.jpg",
        date: getCurrentMonthDate(26),
        time: "4:18am"
      }
    ],
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
  {
    id: 765635,
    title: "Ask secretary for meeting minutes",
    priority: { id: 4545554, name: "High", type: 1, color: red[5] },
    isStarred: true,
    isReplied: false,
    label: [
      {
        id: 213,
        name: "JQuery",
        alias: "jquery",
        label: "JQuery",
        value: "JQuery",
        color: green[5]
      },
      {
        id: 212,
        name: "CSS",
        alias: "css",
        label: "CSS",
        value: "CSS",
        color: blue[5]
      }
    ],
    hasAttachments: true,
    sentAt: "10.30am",
    startDate: getCurrentMonthDate(13),
    endDate: getCurrentMonthDate(15),
    scheduleMobile: "9:30 PM",
    folderValue: 121,
    image: "/assets/images/dummy2.jpg",
    assignedTo: {
      id: 505,
      name: "Andy Caddick",
      image: "/assets/images/avatar/A24.jpg"
    },
    createdBy: {
      name: "Ross Taylor",
      image: "/assets/images/avatar/A28.jpg"
    },
    createdOn: getCurrentMonthDate(9),
    status: 1002,
    comments: [
      {
        comment:
          "Call John at 8:00 PM to cross check the things and get a feedback too.",
        name: "Krishna Kumar",
        image: "/assets/images/avatar/A5.jpg",
        date: getCurrentMonthDate(19),
        time: "2:45am"
      }
    ],
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
  {
    id: 345536,
    title: "To arrange a meeting with React team",
    priority: { id: 4354454, name: "Low", type: 3, color: green[5] },
    isStarred: true,
    isReplied: false,
    label: [
      {
        id: 212,
        name: "CSS",
        alias: "css",
        label: "CSS",
        value: "CSS",
        color: blue[5]
      },
      {
        id: 214,
        name: "Node.js",
        alias: "node",
        label: "Node.js",
        value: "Node.js",
        color: grey[5]
      }
    ],
    hasAttachments: false,
    sentAt: "10.30am",
    startDate: getCurrentMonthDate(13),
    endDate: getCurrentMonthDate(15),
    scheduleMobile: "8:30 PM",
    folderValue: 126,
    image: "/assets/images/dummy2.jpg",
    assignedTo: {
      id: 506,
      name: "Marcus Vaughan",
      image: "/assets/images/avatar/A28.jpg"
    },
    createdBy: {
      name: "Mark Williamson",
      image: "/assets/images/avatar/A29.jpg"
    },
    createdOn: getCurrentMonthDate(8),
    status: 1003,
    comments: [
      {
        comment:
          "Call John at 8:00 PM to cross check the things and get a feedback too.",
        name: "Rehan Kant",
        image: "/assets/images/avatar/A6.jpg",
        date: getCurrentMonthDate(18),
        time: "10:05am"
      }
    ],
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
  {
    id: 654343443,
    title: "Ask secretary for meeting minutes",
    priority: { id: 4545554, name: "High", type: 1, color: red[5] },
    isStarred: true,
    isReplied: false,
    label: [
      {
        id: 213,
        name: "JQuery",
        alias: "jquery",
        label: "JQuery",
        value: "JQuery",
        color: green[5]
      },
      {
        id: 212,
        name: "CSS",
        alias: "css",
        label: "CSS",
        value: "CSS",
        color: blue[5]
      }
    ],
    hasAttachments: true,
    sentAt: "10.30am",
    startDate: getCurrentMonthDate(23),
    endDate: getCurrentMonthDate(25),
    scheduleMobile: "7:30 PM",
    folderValue: 121,
    image: "/assets/images/dummy2.jpg",
    assignedTo: {
      id: 505,
      name: "Andy Caddick",
      image: "/assets/images/avatar/A24.jpg"
    },
    createdBy: {
      name: "Ross Taylor",
      image: "/assets/images/avatar/A28.jpg"
    },
    createdOn: getCurrentMonthDate(22),
    status: 1002,
    comments: [
      {
        comment:
          "Call John at 8:00 PM to cross check the things and get a feedback too.",
        name: "Krishna Kumar",
        image: "/assets/images/avatar/A5.jpg",
        date: getCurrentMonthDate(26),
        time: "4:18am"
      }
    ],
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
  {
    id: 785655,
    title: "Arrange a trip for best performing staff members",
    priority: { id: 9384234, name: "Medium", type: 2, color: blue[5] },
    isStarred: true,
    isReplied: false,
    label: [
      {
        id: 211,
        name: "Html",
        alias: "html",
        label: "Html",
        value: "Html",
        color: red[5]
      },
      {
        id: 213,
        name: "JQuery",
        alias: "jquery",
        label: "JQuery",
        value: "JQuery",
        color: green[5]
      }
    ],
    hasAttachments: false,
    sentAt: "10.30am",
    startDate: getCurrentMonthDate(12),
    endDate: getCurrentMonthDate(15),
    scheduleMobile: "7:30 PM",
    folderValue: 124,
    image: "/assets/images/dummy2.jpg",
    assignedTo: {
      id: 501,
      name: "Johnson",
      image: "/assets/images/avatar/A11.jpg"
    },
    createdBy: {
      name: "Rober Twose",
      image: "/assets/images/avatar/A1.jpg"
    },
    createdOn: getCurrentMonthDate(7),
    status: 1001,
    comments: [
      {
        comment:
          "Call John at 8:00 PM to cross check the things and get a feedback too.",
        name: "Rahul Rajan",
        image: "/assets/images/avatar/A7.jpg",
        date: getCurrentMonthDate(21),
        time: "7:05am"
      }
    ],
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
  {
    id: 234554,
    title: "Schedule an interview for hiring React developer",
    priority: { id: 4354454, name: "Low", type: 3, color: green[5] },
    isStarred: false,
    isReplied: false,
    label: [
      {
        id: 214,
        name: "Node.js",
        alias: "node",
        label: "Node.js",
        value: "Node.js",
        color: grey[5]
      }
    ],
    hasAttachments: false,
    sentAt: "10.30am",
    startDate: getCurrentMonthDate(12),
    endDate: getCurrentMonthDate(14),
    scheduleMobile: "5:30 PM",
    folderValue: 125,
    image: "/assets/images/dummy2.jpg",
    assignedTo: {
      id: 502,
      name: "Joe Root",
      image: "/assets/images/avatar/A15.jpg"
    },
    createdBy: {
      name: "Jessy Ryder",
      image: "/assets/images/avatar/A22.jpg"
    },
    createdOn: getCurrentMonthDate(7),
    status: 1002,
    comments: [
      {
        comment:
          "Call John at 8:00 PM to cross check the things and get a feedback too.",
        name: "Shikhar Dhawan",
        image: "/assets/images/avatar/A8.jpg",
        date: getCurrentMonthDate(22),
        time: "2:12am"
      }
    ],
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
  {
    id: 86764543,
    title: "To arrange a meeting with React team",
    priority: { id: 4354454, name: "Low", type: 3, color: green[5] },
    isStarred: true,
    isReplied: false,
    label: [
      {
        id: 212,
        name: "CSS",
        alias: "css",
        label: "CSS",
        value: "CSS",
        color: blue[5]
      },
      {
        id: 214,
        name: "Node.js",
        alias: "node",
        label: "Node.js",
        value: "Node.js",
        color: grey[5]
      }
    ],
    hasAttachments: false,
    sentAt: "10.30am",
    startDate: getCurrentMonthDate(24),
    endDate: getCurrentMonthDate(26),
    scheduleMobile: "7:30 PM",
    folderValue: 126,
    image: "/assets/images/dummy2.jpg",
    assignedTo: {
      id: 506,
      name: "Marcus Vaughan",
      image: "/assets/images/avatar/A28.jpg"
    },
    createdBy: {
      name: "Mark Williamson",
      image: "/assets/images/avatar/A29.jpg"
    },
    createdOn: getCurrentMonthDate(22),
    status: 1003,
    comments: [
      {
        comment:
          "Call John at 8:00 PM to cross check the things and get a feedback too.",
        name: "Rehan Kant",
        image: "/assets/images/avatar/A6.jpg",
        date: getCurrentMonthDate(18),
        time: "4:18am"
      }
    ],
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
  {
    id: 907645,
    title: "Check the documents of audit",
    priority: { id: 4545554, name: "High", type: 1, color: red[5] },
    isStarred: true,
    isReplied: false,
    label: [
      {
        id: 211,
        name: "Html",
        alias: "html",
        label: "Html",
        value: "Html",
        color: red[5]
      },
      {
        id: 214,
        name: "Node.js",
        alias: "node",
        label: "Node.js",
        value: "Node.js",
        color: grey[5]
      }
    ],
    hasAttachments: true,
    sentAt: "10.30am",
    startDate: getCurrentMonthDate(10),
    endDate: getCurrentMonthDate(12),
    scheduleMobile: "4:30 PM",
    folderValue: 121,
    image: "/assets/images/dummy2.jpg",
    assignedTo: {
      id: 503,
      name: "Monty Panesar",
      image: "/assets/images/avatar/A18.jpg"
    },
    createdBy: {
      name: "Shane Bond",
      image: "/assets/images/avatar/A26.jpg"
    },
    createdOn: getCurrentMonthDate(6),
    status: 1003,
    comments: [
      {
        comment:
          "Call John at 8:00 PM to cross check the things and get a feedback too.",
        name: "shreyas Iyer",
        image: "/assets/images/avatar/A9.jpg",
        date: getCurrentMonthDate(18),
        time: "2:05am"
      }
    ],
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
  {
    id: 678434,
    title: "Download freebie Hello Tune plugin",
    priority: { id: 4545554, name: "High", type: 1, color: red[5] },
    isStarred: true,
    isReplied: false,
    label: [
      {
        id: 212,
        name: "CSS",
        alias: "css",
        label: "CSS",
        value: "CSS",
        color: blue[5]
      },
      {
        id: 214,
        name: "Node.js",
        alias: "node",
        label: "Node.js",
        value: "Node.js",
        color: grey[5]
      }
    ],
    hasAttachments: false,
    sentAt: "10.30am",
    folderValue: 125,
    startDate: getCurrentMonthDate(9),
    endDate: getCurrentMonthDate(10),
    scheduleMobile: "8:30 PM",
    image: "/assets/images/dummy2.jpg",
    assignedTo: {
      id: 504,
      name: "Darren Gough",
      image: "/assets/images/avatar/A21.jpg"
    },
    createdBy: {
      name: "Martin Fergusen",
      image: "/assets/images/avatar/A24.jpg"
    },
    createdOn: getCurrentMonthDate(1),
    status: 1001,
    comments: [
      {
        comment:
          "Call John at 8:00 PM to cross check the things and get a feedback too.",
        name: "Bhuvneshwar Kumar",
        image: "/assets/images/avatar/A10.jpg",
        date: getCurrentMonthDate(18),
        time: "2:55am"
      }
    ],
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
  {
    id: 2344344334,
    title: "Arrange a trip for best performing staff members",
    priority: { id: 9384234, name: "Medium", type: 2, color: blue[5] },
    isStarred: true,
    isReplied: false,
    label: [
      {
        id: 211,
        name: "Html",
        alias: "html",
        label: "Html",
        value: "Html",
        color: red[5]
      },
      {
        id: 213,
        name: "JQuery",
        alias: "jquery",
        label: "JQuery",
        value: "JQuery",
        color: green[5]
      }
    ],
    hasAttachments: false,
    sentAt: "10.30am",
    startDate: getCurrentMonthDate(23),
    endDate: getCurrentMonthDate(26),
    scheduleMobile: "6:30 PM",
    folderValue: 124,
    image: "/assets/images/dummy2.jpg",
    assignedTo: {
      id: 501,
      name: "Johnson",
      image: "/assets/images/avatar/A11.jpg"
    },
    createdBy: {
      name: "Rober Twose",
      image: "/assets/images/avatar/A1.jpg"
    },
    createdOn: getCurrentMonthDate(18),
    status: 1001,
    comments: [
      {
        comment:
          "Call John at 8:00 PM to cross check the things and get a feedback too.",
        name: "Rahul Rajan",
        image: "/assets/images/avatar/A7.jpg",
        date: getCurrentMonthDate(24),
        time: "4:18am"
      }
    ],
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
  {
    id: 789754,
    title: "Arrange a trip for best performing staff members",
    priority: { id: 9384234, name: "Medium", type: 2, color: blue[5] },
    isStarred: true,
    isReplied: false,
    label: [
      {
        id: 213,
        name: "JQuery",
        alias: "jquery",
        label: "JQuery",
        value: "JQuery",
        color: green[5]
      }
    ],
    hasAttachments: false,
    sentAt: "10.30am",
    startDate: getCurrentMonthDate(8),
    endDate: getCurrentMonthDate(10),
    scheduleMobile: "6:30 PM",
    folderValue: 124,
    image: "/assets/images/dummy2.jpg",
    assignedTo: {
      id: 505,
      name: "Andy Caddick",
      image: "/assets/images/avatar/A24.jpg"
    },
    createdBy: {
      name: "Mark Johnson",
      image: "/assets/images/avatar/A26.jpg"
    },
    createdOn: getCurrentMonthDate(5),
    status: 1002,
    comments: [
      {
        comment:
          "Call John at 8:00 PM to cross check the things and get a feedback too.",
        name: "Narendra Kumar",
        image: "/assets/images/avatar/A11.jpg",
        date: getCurrentMonthDate(18),
        time: "2:05am"
      }
    ],
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
  {
    id: 345656,
    title: "Check the documents of audit",
    priority: { id: 4354454, name: "Low", type: 3, color: green[5] },
    isStarred: false,
    isReplied: false,
    label: [],
    hasAttachments: false,
    sentAt: "10.30am",
    startDate: getCurrentMonthDate(2),
    endDate: getCurrentMonthDate(5),
    scheduleMobile: "6:30 PM",
    folderValue: 123,
    image: "/assets/images/dummy2.jpg",
    assignedTo: {
      id: 506,
      name: "Marcus Vaughan",
      image: "/assets/images/avatar/A28.jpg"
    },
    createdBy: {
      name: "Nathan David",
      image: "/assets/images/avatar/A26.jpg"
    },
    createdOn: getCurrentMonthDate(2),
    status: 1003,
    comments: [
      {
        comment:
          "Call John at 8:00 PM to cross check the things and get a feedback too.",
        name: "Baichang Bhutia",
        image: "/assets/images/avatar/A13.jpg",
        date: getCurrentMonthDate(22),
        time: "2:05am"
      }
    ],
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
  {
    id: 767643433,
    title: "Schedule an interview for hiring React developer",
    priority: { id: 4354454, name: "Low", type: 3, color: green[5] },
    isStarred: false,
    isReplied: false,
    label: [
      {
        id: 214,
        name: "Node.js",
        alias: "node",
        label: "Node.js",
        value: "Node.js",
        color: grey[5]
      }
    ],
    hasAttachments: false,
    sentAt: "10.30am",
    startDate: getCurrentMonthDate(1),
    endDate: getCurrentMonthDate(2),
    scheduleMobile: "5:30 PM",
    folderValue: 125,
    image: "/assets/images/dummy2.jpg",
    assignedTo: {
      id: 502,
      name: "Joe Root",
      image: "/assets/images/avatar/A15.jpg"
    },
    createdBy: {
      name: "Jessy Ryder",
      image: "/assets/images/avatar/A22.jpg"
    },
    createdOn: getCurrentMonthDate(21),
    status: 1002,
    comments: [
      {
        comment:
          "Call John at 8:00 PM to cross check the things and get a feedback too.",
        name: "Shikhar Dhawan",
        image: "/assets/images/avatar/A8.jpg",
        date: getCurrentMonthDate(25),
        time: "4:18am"
      }
    ],
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
  {
    id: 856787,
    title: "Arrange birthday party for the staff",
    priority: { id: 9384234, name: "Medium", type: 2, color: blue[5] },
    isStarred: true,
    isReplied: false,
    label: [
      {
        id: 211,
        name: "Html",
        alias: "html",
        label: "Html",
        value: "Html",
        color: red[5]
      },
      {
        id: 212,
        name: "CSS",
        alias: "css",
        label: "CSS",
        value: "CSS",
        color: blue[5]
      }
    ],
    hasAttachments: false,
    sentAt: "10.30am",
    startDate: getCurrentMonthDate(6),
    endDate: getCurrentMonthDate(8),
    scheduleMobile: "8:30 PM",
    folderValue: 121,
    image: "/assets/images/dummy2.jpg",
    assignedTo: {
      id: 501,
      name: "Johnson",
      image: "/assets/images/avatar/A11.jpg"
    },
    createdBy: {
      name: "Michael Holding",
      image: "/assets/images/avatar/A21.jpg"
    },
    createdOn: getCurrentMonthDate(1),
    status: 1001,
    comments: [
      {
        comment:
          "Call John at 8:00 PM to cross check the things and get a feedback too.",
        name: "Rameshwar Sharma",
        image: "/assets/images/avatar/A13.jpg",
        date: getCurrentMonthDate(17),
        time: "2:05am"
      }
    ],
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
  {
    id: 234546,
    title: "Arrange a trip for best performing staff members",
    priority: { id: 4354454, name: "Low", type: 3, color: green[5] },
    isStarred: true,
    isReplied: false,
    label: [],
    hasAttachments: false,
    sentAt: "10.30am",
    startDate: getCurrentMonthDate(5),
    endDate: getCurrentMonthDate(8),
    scheduleMobile: "9:30 PM",
    folderValue: 121,
    image: "/assets/images/dummy2.jpg",
    assignedTo: {
      id: 502,
      name: "Joe Root",
      image: "/assets/images/avatar/A15.jpg"
    },
    createdBy: {
      name: "Shane Warne",
      image: "/assets/images/avatar/A24.jpg"
    },
    createdOn: getCurrentMonthDate(2),
    status: 1002,
    comments: [
      {
        comment:
          "Call John at 8:00 PM to cross check the things and get a feedback too.",
        name: "Tushar Sharma",
        image: "/assets/images/avatar/A15.jpg",
        date: getCurrentMonthDate(24),
        time: "2:05am"
      }
    ],
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
  {
    id: 432445554,
    title: "Check the documents of audit",
    priority: { id: 4545554, name: "High", type: 1, color: red[5] },
    isStarred: true,
    isReplied: false,
    label: [
      {
        id: 211,
        name: "Html",
        alias: "html",
        label: "Html",
        value: "Html",
        color: red[5]
      },
      {
        id: 214,
        name: "Node.js",
        alias: "node",
        label: "Node.js",
        value: "Node.js",
        color: grey[5]
      }
    ],
    hasAttachments: true,
    sentAt: "10.30am",
    startDate: getCurrentMonthDate(20),
    endDate: getCurrentMonthDate(22),
    scheduleMobile: "3:30 PM",
    folderValue: 121,
    image: "/assets/images/dummy2.jpg",
    assignedTo: {
      id: 503,
      name: "Monty Panesar",
      image: "/assets/images/avatar/A18.jpg"
    },
    createdBy: {
      name: "Shane Bond",
      image: "/assets/images/avatar/A26.jpg"
    },
    createdOn: getCurrentMonthDate(18),
    status: 1003,
    comments: [
      {
        comment:
          "Call John at 8:00 PM to cross check the things and get a feedback too.",
        name: "shreyas Iyer",
        image: "/assets/images/avatar/A9.jpg",
        date: getCurrentMonthDate(22),
        time: "4:18am"
      }
    ],
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
  {
    id: 455643,
    title: "Check the documents of audit",
    priority: { id: 4545554, name: "High", type: 1, color: red[5] },
    isStarred: true,
    isReplied: false,
    label: [
      {
        id: 213,
        name: "JQuery",
        alias: "jquery",
        label: "JQuery",
        value: "JQuery",
        color: green[5]
      }
    ],
    hasAttachments: true,
    sentAt: "10.30am",
    startDate: getCurrentMonthDate(4),
    endDate: getCurrentMonthDate(7),
    scheduleMobile: "7:30 PM",
    folderValue: 121,
    image: "/assets/images/dummy2.jpg",
    assignedTo: {
      id: 503,
      name: "Monty Panesar",
      image: "/assets/images/avatar/A18.jpg"
    },
    createdBy: {
      name: "Billy Bowden",
      image: "/assets/images/avatar/A22.jpg"
    },
    createdOn: getCurrentMonthDate(1),
    status: 1003,
    comments: [
      {
        comment:
          "Call John at 8:00 PM to cross check the things and get a feedback too.",
        name: "Rohit Sharma",
        image: "/assets/images/avatar/A22.jpg",
        date: getCurrentMonthDate(25),
        time: "4:05am"
      }
    ],
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
  {
    id: 856121,
    title: "Arrange birthday party for the staff",
    priority: { id: 9384234, name: "Medium", type: 2, color: blue[5] },
    isStarred: true,
    isReplied: false,
    label: [
      {
        id: 211,
        name: "Html",
        alias: "html",
        label: "Html",
        value: "Html",
        color: red[5]
      },
      {
        id: 212,
        name: "CSS",
        alias: "css",
        label: "CSS",
        value: "CSS",
        color: blue[5]
      }
    ],
    hasAttachments: false,
    sentAt: "10.30am",
    startDate: getCurrentMonthDate(3),
    endDate: getCurrentMonthDate(5),
    scheduleMobile: "8:30 PM",
    folderValue: 121,
    image: "/assets/images/dummy2.jpg",
    assignedTo: {
      id: 501,
      name: "Johnson",
      image: "/assets/images/avatar/A11.jpg"
    },
    createdBy: {
      name: "Michael Holding",
      image: "/assets/images/avatar/A21.jpg"
    },
    createdOn: getCurrentMonthDate(2),
    status: 1001,
    comments: [
      {
        comment:
          "Call John at 8:00 PM to cross check the things and get a feedback too.",
        name: "Rameshwar Sharma",
        image: "/assets/images/avatar/A13.jpg",
        date: getCurrentMonthDate(17),
        time: "2:05am"
      }
    ],
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
  {
    id: 2344354334,
    title: "Download freebie Hello Tune plugin",
    priority: { id: 4545554, name: "High", type: 1, color: red[5] },
    isStarred: true,
    isReplied: false,
    label: [
      {
        id: 212,
        name: "CSS",
        alias: "css",
        label: "CSS",
        value: "CSS",
        color: blue[5]
      },
      {
        id: 214,
        name: "Node.js",
        alias: "node",
        label: "Node.js",
        value: "Node.js",
        color: grey[5]
      }
    ],
    hasAttachments: false,
    sentAt: "10.30am",
    folderValue: 125,
    startDate: getCurrentMonthDate(20),
    endDate: getCurrentMonthDate(22),
    scheduleMobile: "5:30 PM",
    image: "/assets/images/dummy2.jpg",
    assignedTo: {
      id: 504,
      name: "Darren Gough",
      image: "/assets/images/avatar/A21.jpg"
    },
    createdBy: {
      name: "Martin Fergusen",
      image: "/assets/images/avatar/A24.jpg"
    },
    createdOn: getCurrentMonthDate(18),
    status: 1001,
    comments: [
      {
        comment:
          "Call John at 8:00 PM to cross check the things and get a feedback too.",
        name: "Bhuvneshwar Kumar",
        image: "/assets/images/avatar/A10.jpg",
        date: getCurrentMonthDate(18),
        time: "4:18am"
      }
    ],
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  },
  {
    id: 233346,
    title: "Arrange a trip for best performing staff members",
    priority: { id: 4354454, name: "Low", type: 3, color: green[5] },
    isStarred: true,
    isReplied: false,
    label: [],
    hasAttachments: false,
    sentAt: "10.30am",
    startDate: getCurrentMonthDate(2),
    endDate: getCurrentMonthDate(5),
    scheduleMobile: "6:30 PM",
    folderValue: 121,
    image: "/assets/images/dummy2.jpg",
    assignedTo: {
      id: 502,
      name: "Joe Root",
      image: "/assets/images/avatar/A15.jpg"
    },
    createdBy: {
      name: "Shane Warne",
      image: "/assets/images/avatar/A24.jpg"
    },
    createdOn: getCurrentMonthDate(1),
    status: 1002,
    comments: [
      {
        comment:
          "Call John at 8:00 PM to cross check the things and get a feedback too.",
        name: "Tushar Sharma",
        image: "/assets/images/avatar/A15.jpg",
        date: getCurrentMonthDate(24),
        time: "2:05am"
      }
    ],
    content:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged."
  }
];
export default todoList;
