import saMessages from "../locales/tr_TR.json";
import trTR from "antd/lib/locale/tr_TR";

const saLang = {
  messages: {
    ...saMessages
  },
  antLocale: trTR,
  locale: "tr-TR"
};
export default saLang;
