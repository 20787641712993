// import React from 'react';
// import {notification} from '@mj/mockapi/fakedb';
// import {Dropdown} from 'antd';

// import IntlMessages from '@mj/helpers/IntlMessages';
// import NotificationItem from './NotificationItem';
// import {IoIosNotificationsOutline} from 'react-icons/io';
// import {
//   StyledDrowdownWrapper,
//   StyledNotifyButtonAll,
//   StyledNotifyIcon,
//   StyledNotifyLink,
//   StyledNotifyList,
//   StyledNotifyScrollSubmenu,
//   StyledNotifyText,
// } from './index.styled';

// const items = [
//   {
//     key: 1,
//     label: (
//       <span className='header'>
//         <IntlMessages id='common.notifications' />({notification.length})
//       </span>
//     ),
//   },
//   {
//     key: 2,
//     label: (
//       <StyledNotifyScrollSubmenu>
//         <StyledNotifyList
//           dataSource={notification}
//           renderItem={(item) => {
//             return <NotificationItem key={item.id} item={item} />;
//           }}
//         />
//       </StyledNotifyScrollSubmenu>
//     ),
//   },
//   {
//     key: 3,
//     label: (
//       <StyledNotifyButtonAll type='primary'>
//         <IntlMessages id='common.viewAll' />
//       </StyledNotifyButtonAll>
//     ),
//   },
// ];
// const AppNotifications = () => {
//   return (
//     <StyledDrowdownWrapper>
//       <Dropdown
//         menu={{items}}
//         className='dropdown'
//         overlayClassName='header-notify-messages'
//         getPopupContainer={(triggerNode) => triggerNode}
//         trigger={['click']}
//       >
//         <StyledNotifyLink onClick={(e) => e.preventDefault()}>
//           <StyledNotifyIcon>
//             <IoIosNotificationsOutline />
//           </StyledNotifyIcon>
//           <StyledNotifyText>
//             <IntlMessages id='common.notifications' />
//           </StyledNotifyText>
//         </StyledNotifyLink>
//       </Dropdown>
//     </StyledDrowdownWrapper>
//   );
// };

// export default AppNotifications;
import React from "react";
//import notification from '../../services/db/notifications/notification';
import { Badge, Dropdown } from "antd";

import NotificationItem from "./NotificationItem";
import { IoIosNotificationsOutline } from "react-icons/io";
import {
  StyledDrowdownWrapper,
  StyledNotifyButtonAll,
  StyledNotifyIcon,
  StyledNotifyLink,
  StyledNotifyList,
  StyledNotifyScrollSubmenu,
  StyledNotifyText
} from "./index.styled";

import { useNavigate } from "react-router-dom";
import { useGetData } from "@mj/services/auth/ezAPI";
import IntlMessages from "@mj/helpers/IntlMessages";

const AppNotifications = () => {
  const [{ apiData: notification }, { setRefreshing }] = useGetData(
    "meta/notification/my-list",
    []
  );
  // console.log(notification);
  const navigate = useNavigate();
  const items = [
    {
      key: 1,
      label: (
        <span className="header">
          <IntlMessages id="common.notifications" /> ({notification?.length})
        </span>
      )
    },
    {
      key: 2,
      label: (
        <StyledNotifyScrollSubmenu>
          <StyledNotifyList
            dataSource={notification}
            itemLayout="vertical"
            renderItem={(item) => {
              return (
                <NotificationItem
                  setRefreshing={setRefreshing}
                  key={item._id}
                  item={item}
                />
              );
            }}
          />
        </StyledNotifyScrollSubmenu>
      )
    },
    {
      key: 3,
      label: (
        <StyledNotifyButtonAll
          onClick={() => {
            navigate("/notifications");
          }}
          type="primary"
        >
          <IntlMessages id="common.viewAll" />
        </StyledNotifyButtonAll>
      )
    }
  ];
  return (
    <Badge size="small" overflowCount={99} count={notification?.length}>
      <StyledDrowdownWrapper>
        <Dropdown
          menu={{ items }}
          className="dropdown header-notification"
          overlayClassName="header-notify-messages"
          getPopupContainer={(triggerNode) => triggerNode}
          trigger={["click"]}
        >
          <StyledNotifyLink
            onClick={(e) => {
              e.preventDefault();
              setRefreshing();
            }}
          >
            <StyledNotifyIcon>
              <IoIosNotificationsOutline />
            </StyledNotifyIcon>
            {/* <StyledNotifyText>
              <IntlMessages id='common.notifications' />
            </StyledNotifyText> */}
          </StyledNotifyLink>
        </Dropdown>
      </StyledDrowdownWrapper>
    </Badge>
  );
};

export default AppNotifications;
