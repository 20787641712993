import React from "react";

import PropTypes from "prop-types";
import { Button, List } from "antd";
import {
  StyledNotifyListItem,
  StyledNotifyMsgAvatar,
  StyleTime
} from "./NotificationItem.styled";
import moment from "moment";
import jwtAxios from "@mj/services/auth/jwt-auth";
import { useNavigate } from "react-router-dom";
import { StyledCrUserInfoAvatar } from "@mj/components/AppLayout/components/UserInfo/index.styled";
import { BiLinkExternal } from "react-icons/bi";

const NotificationItem = (props) => {
  const { item, setRefreshing } = props;

  const navigate = useNavigate();
  const getUserAvatar = () => {
    if (item.senderId?.name) {
      return item.senderId?.name.charAt(0).toUpperCase();
    }
    if (item.senderId?.email) {
      return item.senderId?.email.charAt(0).toUpperCase();
    }
  };
  const handleClick = async () => {
    await jwtAxios.patch(`meta/notification/update/${item._id}`, {
      isRead: true
    });

    setRefreshing(true);
  };
  return (
    <StyledNotifyListItem
      className="item-hover"
      actions={[
        <Button size="small" hidden={item.isRead} onClick={handleClick} key={1}>
          Mark as Read
        </Button>,
        <Button
          key={2}
          size="small"
          hidden={!item.path}
          onClick={() => navigate(item.path)}
          icon={<BiLinkExternal />}
        >
          Document
        </Button>
      ]}
    >
      <List.Item.Meta
        size={"small"}
        avatar={
          item.senderId?.picture[0]?.url ? (
            <StyledNotifyMsgAvatar
              src={item.senderId?.picture[0]?.url}
              alt={item.senderId?.name}
            />
          ) : (
            <StyledCrUserInfoAvatar>{getUserAvatar()}</StyledCrUserInfoAvatar>
          )
        }
        title={
          <>
            {item.senderId?.name} {item.senderId?.lastName}
            <StyleTime className="message-time">
              {moment(item.createdAt).fromNow()}
            </StyleTime>
          </>
        }
        description={item.message}
      />
    </StyledNotifyListItem>
  );
};

export default NotificationItem;

NotificationItem.propTypes = {
  item: PropTypes.object.isRequired
};
