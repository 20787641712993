import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { Badge, Dropdown, notification, Radio, Switch, Typography } from "antd";
import { FaChevronDown } from "react-icons/fa";
import { useThemeContext } from "@mj/context/AppContextProvider/ThemeContextProvider";
import { useAuthMethod, useAuthUser } from "@mj/hooks/AuthHooks";
import { useSidebarContext } from "@mj/context/AppContextProvider/SidebarContextProvider";
import PropTypes from "prop-types";
import {
  StyledCrUserDesignation,
  StyledCrUserInfo,
  StyledCrUserInfoAvatar,
  StyledCrUserInfoContent,
  StyledCrUserInfoInner,
  StyledMsgListItem,
  StyledMsgListItemContent,
  StyledText,
  StyledUserArrow,
  StyledUsername,
  StyledUsernameInfo
} from "./index.styled";
import { useGetData } from "@mj/services/auth/ezAPI";
import jwtAxios from "@mj/services/auth/jwt-auth";
import successHandler from "@mj/services/auth/successHandler";
import errorHandler from "@mj/services/auth/errorHandler";
import { useJWTAuth } from "@mj/services/auth/JWTAuthProvider";

const UserInfo = ({ hasColor }) => {
  const { themeMode } = useThemeContext();
  const { logout } = useAuthMethod();
  const { isBranchRestrict, setIsBranchRestrict } = useJWTAuth();

  const { user } = useAuthUser();
  const { setBranches, branches } = useAuthMethod();

  // Load data from localStorage on first render
  useEffect(() => {
    const storedData = localStorage.getItem("isBranchRestrict");
    if (storedData) {
      setIsBranchRestrict(JSON.parse(storedData) === true);
    }
  }, []);

  // Handle change to toggle and save/delete in localStorage
  const handleChangeRestrict = () => {
    const newValue = !isBranchRestrict;
    setIsBranchRestrict(newValue);
    notification.config({
      duration: 30,
      placement: "topRight"
    });

    if (newValue) {
      localStorage.setItem("isBranchRestrict", JSON.stringify(newValue));
      notification.success({
        message: "Restrict Based On Branch",
        description:
          "Data will be displayed based on current selected branch. ",
        placement: "topRight"
      });
    } else {
      localStorage.removeItem("isBranchRestrict");
      notification.info({
        message: "Restriction removed",
        description: "Data will be displayed based on permissions. ",
        placement: "topRight"
      });
    }
  };
  const fetchData = async () => {
    try {
      const res = await jwtAxios.get("meta/company/branch/rolebaselist");
      setBranches(res.data.result);
    } catch (error) {
      console.log(error);
    }
  };
  console.log(user);
  useEffect(() => {
    if (user) {
      fetchData();
    }
  }, []);
  const [companySelectName, setCompanySelectName] = useState(
    user?.currLocation
  );
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const navigate = useNavigate();
  const { sidebarColorSet } = useSidebarContext();
  const { allowSidebarBgImage } = useSidebarContext();
  const handleChange = async (branch) => {
    try {
      const response = await jwtAxios.put(`admin/auth/changebranch/${branch}`);
      setCompanySelectName(branches?.find((p) => p._id === branch));
      console.log(response);
      successHandler(response);
      window.location.reload();
    } catch (error) {
      errorHandler(error);
    }
  };
  const handleCompanySwitch = (companyTitle, checked) => {
    if (checked) {
      setSelectedCompanies((prevCompanies) => [...prevCompanies, companyTitle]);
    } else {
      setSelectedCompanies((prevCompanies) =>
        prevCompanies.filter((company) => company !== companyTitle)
      );
    }
  };
  const getUserAvatar = () => {
    if (user.displayName) {
      return user.displayName.charAt(0).toUpperCase();
    }
    if (user.email) {
      return user.email.charAt(0).toUpperCase();
    }
  };
  const textWidth = { width: "240px" };
  const items = [
    {
      key: 1,
      label: (
        <div
          style={{ fontSize: "18px", fontWeight: "700" }}
          onClick={() => navigate("/my-profile")}
        >
          👤 My Profile
        </div>
      )
    },

    {
      key: 33,
      label: (
        <div
          style={{ fontSize: "18px", fontWeight: "700" }}
          onClick={() => logout()}
        >
          🔓 Logout
        </div>
      )
    },
    {
      key: 2,
      label: (
        <>
          Selected Branch Data{" "}
          <Switch
            style={{ width: "40px" }}
            size="large"
            value={isBranchRestrict}
            onChange={() => handleChangeRestrict()}
          />
        </>
      )
    },
    {
      key: 3,
      label: (
        <>
          <StyledText>Select Branch</StyledText>
          <Radio.Group
            className="w-full"
            value={companySelectName?._id}
            onChange={(e) => handleChange(e.target.value)}
          >
            {branches?.map((item, index) => (
              <StyledMsgListItem
                key={index}
                className="item-hover flex justify-bet"
                style={{
                  // background: 'green',
                  display: "flex"
                }}
              >
                <Radio value={item?._id} style={{ width: "250px" }}>
                  <StyledMsgListItemContent className="flex items-center">
                    <h3 style={textWidth}>
                      <Typography.Text
                        ellipsis={{
                          tooltip: `${item?.parentCompany?.name}>${item.branchName}`
                        }}
                      >
                        {item?.branchName}
                      </Typography.Text>
                    </h3>
                  </StyledMsgListItemContent>
                </Radio>
                {/* <Switch
                  style={{width: '20px'}}
                  size='small'
                  onChange={(checked) => handleCompanySwitch(item._id, checked)}
                /> */}
              </StyledMsgListItem>
            ))}
          </Radio.Group>
        </>
      )
    }
  ];

  return (
    <>
      {hasColor ? (
        <StyledCrUserInfo
          style={{
            backgroundColor: allowSidebarBgImage
              ? ""
              : sidebarColorSet.sidebarHeaderColor,
            color: sidebarColorSet.sidebarTextColor
          }}
          className={clsx("cr-user-info", {
            light: themeMode === "light"
          })}
        >
          <Dropdown
            menu={{ items }}
            trigger={["click"]}
            placement="bottomRight"
            overlayStyle={{
              zIndex: 1052,
              minWidth: 150
            }}
          >
            <StyledCrUserInfoInner className="ant-dropdown-link">
              {user.photoURL ? (
                <StyledCrUserInfoAvatar src={user.photoURL} />
              ) : (
                <StyledCrUserInfoAvatar src={"/assets/avatar.png"} />
              )}
              <StyledCrUserInfoContent className="cr-user-info-content">
                <StyledUsernameInfo>
                  <StyledUsername
                    className={clsx("text-truncate", {
                      light: themeMode === "light"
                    })}
                  >
                    <Badge dotSize={12} size="large" status="processing" />{" "}
                    {user?.currLocation?.branchName}
                  </StyledUsername>
                  <StyledUserArrow className="cr-user-arrow">
                    <FaChevronDown />
                  </StyledUserArrow>
                </StyledUsernameInfo>
                <StyledCrUserDesignation className="text-truncate">
                  {`${user.name || ""} ${user.lastName || ""}`}
                </StyledCrUserDesignation>
              </StyledCrUserInfoContent>
            </StyledCrUserInfoInner>
          </Dropdown>
        </StyledCrUserInfo>
      ) : (
        <StyledCrUserInfo
          className={clsx("cr-user-info", {
            light: themeMode === "light"
          })}
        >
          <Dropdown
            menu={{ items }}
            trigger={["click"]}
            placement="bottomRight"
            overlayStyle={{
              zIndex: 1052,
              minWidth: 150
            }}
          >
            <StyledCrUserInfoInner className="ant-dropdown-link">
              {user.photoURL ? (
                <StyledCrUserInfoAvatar src={user.photoURL} />
              ) : (
                <StyledCrUserInfoAvatar>
                  {getUserAvatar()}
                </StyledCrUserInfoAvatar>
              )}
              <StyledCrUserInfoContent className="cr-user-info-content">
                <StyledUsernameInfo>
                  <StyledUsername
                    className={clsx("text-truncate", {
                      light: themeMode === "light"
                    })}
                  >
                    <Badge size="large" status="processing" />{" "}
                    {user?.currLocation?.parentCompany?.name}
                  </StyledUsername>
                  <StyledUserArrow className="cr-user-arrow">
                    <FaChevronDown />
                  </StyledUserArrow>
                </StyledUsernameInfo>
                <StyledCrUserDesignation className="text-truncate cr-user-designation">
                  {`${user.name || ""} ${user.lastName || ""}`}
                </StyledCrUserDesignation>
              </StyledCrUserInfoContent>
            </StyledCrUserInfoInner>
          </Dropdown>
        </StyledCrUserInfo>
      )}
    </>
  );
};

export default UserInfo;

UserInfo.propTypes = {
  hasColor: PropTypes.bool
};
