import React, { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useInfoViewActionsContext } from "@mj/context/AppContextProvider/InfoViewContextProvider";
import jwtAxios, { setAuthToken } from "./jwt-auth/index";
import { useNavigate } from "react-router-dom";

const JWTAuthContext = createContext();
const JWTAuthActionsContext = createContext();

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

const JWTAuthAuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [jwtData, setJWTAuthData] = useState({
    user: null,
    isAuthenticated: false,
    isLoading: true
  });
  const [isBranchRestrict, setIsBranchRestrict] = useState(false);
  const infoViewActionsContext = useInfoViewActionsContext();
  const getAuthUser = () => {
    const token = localStorage.getItem("token");

    if (!token) {
      setJWTAuthData({
        user: undefined,
        isLoading: false,
        isAuthenticated: false
      });
      return;
    }
    setAuthToken(token);

    jwtAxios
      .get("admin/auth")
      .then(({ data }) =>
        setJWTAuthData({
          user: data,
          isLoading: false,
          isAuthenticated: true
        })
      )
      .catch(() => {
        localStorage.removeItem("token");
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false
        });
      });
  };
  useEffect(() => {
    getAuthUser();
  }, []);

  const signInUser = async (loginAdminData) => {
    infoViewActionsContext.fetchStart();
    try {
      const { data } = await jwtAxios.post(
        `auth/login?timestamp=${new Date().getTime()}`,
        loginAdminData
      );
      setAuthToken(data.result.token);
      // localStorage.setItem('token', data.token);
      if (data.success === true) {
        setJWTAuthData({
          user: data.result.admin,
          isAuthenticated: true,
          isLoading: false
        });
      }
      navigate("/donation");
      infoViewActionsContext.fetchSuccess();
    } catch (error) {
      setJWTAuthData({
        ...jwtData,
        isAuthenticated: false,
        isLoading: false
      });
      infoViewActionsContext.fetchError(
        error?.response?.data?.message || "Something went wrong"
      );
    }
  };
  const logout = async () => {
    setJWTAuthData({ ...jwtData, isLoading: true });
    try {
      await jwtAxios.get("admin/auth");
      localStorage.removeItem("token");
      setAuthToken();
      setJWTAuthData({
        user: null,
        isLoading: false,
        isAuthenticated: false
      });
    } catch (error) {
      localStorage.removeItem("token");
      setAuthToken();
      setJWTAuthData({
        user: null,
        isLoading: false,
        isAuthenticated: false
      });
    }
  };

  const [branches, setBranches] = useState([]);

  return (
    <JWTAuthContext.Provider
      value={{
        branches,
        isBranchRestrict,
        setIsBranchRestrict,
        ...jwtData
      }}
    >
      <JWTAuthActionsContext.Provider
        value={{
          signInUser,
          logout,
          getAuthUser,
          branches,
          setBranches,
          isBranchRestrict,
          setIsBranchRestrict
        }}
      >
        {children}
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default JWTAuthAuthProvider;

JWTAuthAuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};
