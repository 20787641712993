import { blue, green, grey, red } from "@ant-design/colors";

const labelList = [
  {
    id: 211,
    name: "Crema",
    alias: "crema",
    color: red[5]
  },
  {
    id: 212,
    name: "Personal",
    alias: "personal",
    color: blue[5]
  },
  {
    id: 213,
    name: "Work",
    alias: "work",
    color: green[5]
  },
  {
    id: 214,
    name: "Paypal",
    alias: "paypal",
    color: grey[5]
  }
];
export default labelList;
