const title = "Donation";
const dataRoute = "erp/donation";
const navPath = "/donation";

export const dataInfo = {
  formTitle: title,
  LogTitle: "Donation",
  tableTitle: title,
  metaData: title,
  dataRoute,
  navPath,
  title,
  permission: "donation" //user //admin // moduleName
};
