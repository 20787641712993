const languageData = [
  {
    languageId: "english",
    locale: "en",
    name: "English",
    icon: "us"
  },
  {
    languageId: "turkish",
    locale: "tr",
    name: "Türkçe",
    icon: "tr"
  }
];
export default languageData;
