import React from "react";
import { RoutePermittedRole } from "@mj/constants/AppEnums";

const ECommerce = React.lazy(() => import("modules/dashboards/ECommerce"));

export const dashboardConfig = [
  {
    permittedRole: RoutePermittedRole.user,
    path: "/dashboards/admin",
    element: <ECommerce />
  }
];
