import React, { useState } from "react";
import { Button, Col, Form, Input } from "antd";
import AppRowContainer from "@mj/components/AppRowContainer";
import IntlMessages from "@mj/helpers/IntlMessages";
import {
  StyledUserProfileForm,
  StyledUserProfileFormTitle,
  StyledUserProfileGroupBtn
} from "../index.styled";
import { useAuthMethod, useAuthUser } from "@mj/hooks/AuthHooks";
import jwtAxios from "@mj/services/auth/jwt-auth";
import successHandler from "@mj/services/auth/successHandler";
import errorHandler from "@mj/services/auth/errorHandler";
const ChangePassword = () => {
  const { user } = useAuthUser();
  const { logout } = useAuthMethod();
  const [loading, setLoading] = useState(false);
  const onFinish = async (values) => {
    try {
      setLoading(true);
      const res = await jwtAxios.patch(
        `admin/password-update/${user?._id}`,
        values
      );

      successHandler(res);
      setLoading(false);
      logout();
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <StyledUserProfileForm
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <StyledUserProfileFormTitle>
        <IntlMessages id="userProfile.changePassword" />
      </StyledUserProfileFormTitle>
      <AppRowContainer gutter={16}>
        <Col xs={24} md={12}>
          <Form.Item
            name="oldPassword"
            rules={[
              { required: true, message: "Please input your Enter Password" }
            ]}
          >
            <Input.Password placeholder="Enter password" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12} />
        <Col xs={24} md={12}>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: "Please input your New Password!" }
            ]}
          >
            <Input.Password placeholder="Enter new password" />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: "Please input Your Confirm Password!"
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    "The Confirm Password that you entered do not match!"
                  );
                }
              })
            ]}
          >
            <Input.Password placeholder="Confirm new password" />
          </Form.Item>
        </Col>
        <Col xs={24} md={24}>
          <StyledUserProfileGroupBtn
            shouldUpdate
            className="user-profile-group-btn"
          >
            <Button type="primary" htmlType="submit" loading={loading}>
              Save Changes
            </Button>
            <Button htmlType="cancel">Cancel</Button>
          </StyledUserProfileGroupBtn>
        </Col>
      </AppRowContainer>
    </StyledUserProfileForm>
  );
};

export default ChangePassword;
