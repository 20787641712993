import { RoutePermittedRole } from "@mj/constants/AppEnums";
import React from "react";
const Target = React.lazy(() => import("./target"));
const TargetForm = React.lazy(() => import("./target/Form"));
const Places = React.lazy(() => import("./Places"));
const PlaceForm = React.lazy(() => import("./Places/Form"));
const Donor = React.lazy(() => import("./Donor"));
const DonorForm = React.lazy(() => import("./Donor/Form"));
const Donation = React.lazy(() => import("./Donation"));
const DonationForm = React.lazy(() => import("./Donation/DonationForm"));
const Reconcile = React.lazy(() => import("./Reconcile"));
const ReconcileList = React.lazy(() => import("./Reconcile/Table"));
const ReconcileDetail = React.lazy(
  () => import("./Reconcile/ReconcileDetailComponent")
);
const Category = React.lazy(() => import("./Fond"));
const CategoryForm = React.lazy(() => import("./Fond/ReceiptForm"));
const Currency = React.lazy(() => import("./Currency"));
const CurrencyForm = React.lazy(() => import("./Currency/Form"));
const Bank = React.lazy(() => import("./Bank"));
const BankForm = React.lazy(() => import("./Bank/Form"));
const Import = React.lazy(() => import("./Bank/Import"));
const CashImport = React.lazy(() => import("./Cash/Import"));
const ImportDonation = React.lazy(() => import("./Donation/Import"));
const Cash = React.lazy(() => import("./Cash"));
const CashForm = React.lazy(() => import("./Cash/Form"));
const WaterWell = React.lazy(() => import("./Waterwell"));
const WaterWellForm = React.lazy(() => import("./Waterwell/ReceiptForm"));
const Executor = React.lazy(() => import("./Executor"));
const ExecutorForm = React.lazy(() => import("./Executor/ReceiptForm"));
const ExecutorList = React.lazy(() => import("./ExecutorList"));
const Chat = React.lazy(() => import("./Chat"));
const ExecutorListForm = React.lazy(() => import("./ExecutorList/ReceiptForm"));
const Notifications = React.lazy(() => import("./Notifications"));
const Sms = React.lazy(() => import("./SMS"));

export const targetConfig = [
  {
    permittedRole: RoutePermittedRole.reconcile,
    path: ["/reconcile/workspace"],
    element: <Reconcile />
  },

  {
    permittedRole: RoutePermittedRole.chat,
    path: ["/chat"],
    element: <Chat />
  },
  {
    permittedRole: RoutePermittedRole.executorList,
    path: ["/executorlist"],
    element: <ExecutorList />
  },
  {
    permittedRole: RoutePermittedRole.executorList,
    path: ["/executorlist/workspace", "/executorlist/workspace/:id"],
    element: <ExecutorListForm />
  },
  {
    permittedRole: RoutePermittedRole.reconcile,
    path: ["/reconcile/"],
    element: <ReconcileList />
  },
  {
    permittedRole: RoutePermittedRole.reconcile,
    path: ["/reconcile/detail/:id"],
    element: <ReconcileDetail />
  },
  {
    permittedRole: RoutePermittedRole.bank,
    path: ["/transactions/bank"],
    element: <Bank />
  },
  {
    permittedRole: RoutePermittedRole.bank,
    path: ["/transactions/bank/import"],
    element: <Import />
  },
  {
    permittedRole: RoutePermittedRole.donation,
    path: ["/donation/import"],
    element: <ImportDonation />
  },
  {
    permittedRole: RoutePermittedRole.bank,
    path: ["/transactions/bank/workspace", "/transactions/bank/workspace/:id"],
    element: <BankForm />
  },
  {
    permittedRole: RoutePermittedRole.cash,
    path: ["/transactions/cash/import"],
    element: <CashImport />
  },
  {
    permittedRole: RoutePermittedRole.cash,
    path: ["/transactions/cash"],
    element: <Cash />
  },
  {
    permittedRole: RoutePermittedRole.sms,
    path: ["/sms"],
    element: <Sms />
  },
  {
    permittedRole: RoutePermittedRole.currency,
    path: ["/currency/workspace", "/currency/workspace/:id"],
    element: <CurrencyForm />
  },
  {
    permittedRole: RoutePermittedRole.currency,
    path: ["/currency"],
    element: <Currency />
  },
  {
    permittedRole: RoutePermittedRole.executor,
    path: ["/executor/workspace", "/executor/workspace/:id"],
    element: <ExecutorForm />
  },
  {
    permittedRole: RoutePermittedRole.executor,
    path: ["/executor"],
    element: <Executor />
  },
  {
    permittedRole: RoutePermittedRole.cash,
    path: ["/transactions/cash/workspace", "/transactions/cash/workspace/:id"],
    element: <CashForm />
  },
  {
    permittedRole: RoutePermittedRole.waterwell,
    path: ["/waterwells"],
    element: <WaterWell />
  },
  {
    permittedRole: RoutePermittedRole.waterwell,
    path: ["/waterwells/workspace", "/waterwells/workspace/:id"],
    element: <WaterWellForm />
  },
  {
    permittedRole: RoutePermittedRole.target,
    path: ["/target"],
    element: <Target />
  },
  {
    permittedRole: RoutePermittedRole.target,
    path: ["/target/workspace", "/target/workspace/:id"],
    element: <TargetForm />
  },

  {
    permittedRole: RoutePermittedRole.placeList,
    path: ["/places"],
    element: <Places />
  },
  {
    permittedRole: RoutePermittedRole.placeList,
    path: ["/places/workspace", "/places/workspace/:id"],
    element: <PlaceForm />
  },
  {
    permittedRole: RoutePermittedRole.donor,
    path: ["/donor"],
    element: <Donor />
  },
  {
    permittedRole: RoutePermittedRole.donor,
    path: ["/donor/workspace", "/donor/workspace/:id"],
    element: <DonorForm />
  },
  {
    permittedRole: RoutePermittedRole.donation,
    path: ["/donation"],
    element: <Donation />
  },
  {
    permittedRole: RoutePermittedRole.donation,
    path: ["/donation/workspace", "/donation/workspace/:id"],
    element: <DonationForm />
  },
  {
    permittedRole: RoutePermittedRole.fondList,
    path: ["/fond"],
    element: <Category />
  },
  {
    permittedRole: RoutePermittedRole.fondList,
    path: ["/fond/workspace", "/fond/workspace/:id"],
    element: <CategoryForm />
  },
  {
    permittedRole: RoutePermittedRole.notifications,
    path: ["/notifications"],
    element: <Notifications />
  }
];
