import styled from "styled-components";

export const StyledAppLogo = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;

  & img {
    height: 100%;
    width: 150px;
    margin-right: 10px;
    object-fit: contain;
    aspect-ratio: 4/1;
  }

  @media only screen and (max-width: 700px) {
    & img {
      width: 30%;
      margin-left: 10px;
    }
  }
  @media only screen and (max-width: 500px) {
    & img {
      width: 100px;
      margin-left: 5px;
    }
  }

  /* @media only screen and (max-width: 700px) {
    & img {
      aspect-ratio: 3/4;
      object-fit: cover;
      object-position: left;
      margin-left: 10px;
      width: 10%;
    }

    [dir='rtl'] & img {
      margin-left: 0;
      margin-right: 10px;
    }
  }
  @media only screen and (max-width: 500px) {
    & img {
      aspect-ratio: 3/4;
      object-fit: cover;
      object-position: left;
      margin-left: 10px;
      width: 12%;
    }

    [dir='rtl'] & img {
      margin-left: 0;
      margin-right: 10px;
    }
  } */
`;
